import { Controller } from '@hotwired/stimulus'
import { useEventListener } from 'stimulus-library'

/**
 * Toggle the aria-busy attribute on the input field while the form is being submitted.
 */
export default class BusyFormController extends Controller {
  static targets = ['indicator']

  connect() {
    super.connect()

    useEventListener(this, this.element, 'turbo:submit-start', this.startSubmit)
    useEventListener(this, this.element, 'turbo:submit-end', this.endSubmit)
  }

  startSubmit(event) {
    this.indicatorTarget.disabled = true
    this.indicatorTarget.setAttribute("aria-busy", "true");
  }

  endSubmit(event) {
    this.indicatorTarget.setAttribute("aria-busy", "false");
    this.indicatorTarget.disabled = false
  }

}