

export function isBlank(value) {
  return value === undefined ||
         value === null ||
         value === false ||
         (typeof value === 'string' && value.trim() === '')
}

export function isPresent(value) {
  return !isBlank(value)
}

function elementSupportsValueAttribute(element) {
  return element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLOptionElement ||
    element instanceof HTMLSelectElement
}

export function setElementValue(element, value) {
  element.dataset.value = value
  if (elementSupportsValueAttribute(element)) {
    element.type === "checkbox" ?
      element.checked = value :
      element.value = value
    element.dispatchEvent(new Event('change'))
  } else {
    element.innerText = value
  }
}

export function elementValue(element) {
  if (elementSupportsValueAttribute(element)) {
    switch(element.type) {
      case "checkbox":
        return element.checked
      case "select-multiple":
        return sortedMultiSelectOptionValues(element)
      default:
        return element.value
    }
  } else {
    return element.type === 'fieldset' ?
      null :
      element.innerText
  }
}

export function inputElement(element) {
  return element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLSelectElement
}

function elementSupportsDisabledAttribute(element) {
  return element instanceof HTMLInputElement ||
    element instanceof HTMLButtonElement ||
    element instanceof HTMLFieldSetElement ||
    element instanceof HTMLOptGroupElement ||
    element instanceof HTMLOptionElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLSelectElement
}
export function setElementEnabled(element, enabled) {
  if (elementSupportsDisabledAttribute(element)) {
    element.disabled = !enabled
  } else {
    element.classList.toggle('disabled', !enabled)
  }
}

export function sortedMultiSelectOptionValues(element) {
  return Array.from(element.selectedOptions)
    .map(option => option.value)
    .sort()
    .join()
}
