import { Controller } from '@hotwired/stimulus';

/**
 * A stimulus controller to automatically collapse or expand the nodes of a Shoelace tree.
 */
export default class TreeExpansionController extends Controller {
  static targets = ['treeItem', 'toggle']
  static values = {
    expanded: Boolean
  }

  toggleCollapse() {
    if (this.expandedValue) {
      return this.collapseAll()
    }
    this.expandAll();
  }

  expandAll() {
    this.treeItemTargets.forEach(item => this.expandItem(item))
    this.expandedValue = true
    this.updateToggleText()
  }

  collapseAll() {
    this.treeItemTargets.forEach(item => this.collapseItem(item))
    this.expandedValue = false
    this.updateToggleText()
  }

  expandItem(item) {
    item.setAttribute('expanded', true)
  }

  collapseItem(item) {
    item.removeAttribute('expanded')
  }

  updateExpandedValue() {
    this.expandedValue = this.treeItemTargets.every(item => item.hasAttribute('expanded'))
    this.updateToggleText()
  }

  updateToggleText() {
    if (this.expandedValue) {
      this.toggleTarget.innerText = 'Collapse all'
      return
    }
    this.toggleTarget.innerText = 'Expand all'
  }
}