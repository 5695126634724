import { Controller as StimulusController } from '@hotwired/stimulus'

export default class TurboModalController extends StimulusController {
  static targets = ['header', 'contents']

  loaded() {
    this.open()
    if (this.hasHeaderTarget && this.headerTarget.dataset.additionalClasses !== '') {
      this.element.classList.add(this.headerTarget.dataset.additionalClasses)
    }
  }

  open() {
    this.element.show()
  }

  close() {
    this.element.hide()
  }

  onHide() {
    setTimeout(() => {
      if (this.hasHeaderTarget && this.headerTarget.dataset.additionalClasses !== '') {
        this.element.classList.remove(this.headerTarget.dataset.additionalClasses)
      }
      if (this.hasContentsTarget) {
        this.contentsTarget.replaceChildren()
      }
    }, 100) // Ensure the modal has finished its hide animation before clearing the contents out of it.
  }
}
