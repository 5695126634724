import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

/**
 * Specialized configuration for TomSelect. See https://tom-select.js.org/docs/ for the glossary. Specifically:
 * Given this vocabulary:
 *  - item: the thing selected
 *  - option: the choices to pick from
 *
 * Features:
 *  - Tooltip for long items (see the stimulus v
 *  - Multiline options (allows for a first line label with a second line subtext)
 */
export default class TomSelectController extends Controller {
  static targets = ['dropdown']

  static values = {
    /**
     * Stimulus values containing configuration data for TomSelect.
     * Defaults set to work with the default Rails form helpers.
     */
    valueField: {
      type: String,
      default: 'id',
    },
    labelField: {
      type: String,
      default: 'name',
    },
    searchField: {
      type: Array,
      default: ['name'],
    },
    /**
     * Character that, when present, will separate multiline options into a label and subtext.
     */
    multiLineDelimiter: {
      type: String,
      default: ''
    },
    create: {
      type: Boolean,
      default: false
    },
    createOnBlur: {
      type: Boolean,
      default: false
    },
    delimiter: {
      type: String,
      default: ','
    },

    /**
     * HTML template to be used in rendering the item
     */
    itemTemplate: String
  }

  connect() {
    super.connect()
    this.tomSelect = new TomSelect(this.dropdownTarget, this.baseSettings)

    // remove this when tom-select supports readonly
    // https://github.com/orchidjs/tom-select/issues/587
    if (this.dropdownTarget.getAttribute('readonly') === 'readonly') {
      this.tomSelect.lock()
    }
  }

  disconnect() {
    super.disconnect()
    this.tomSelect.destroy()
  }

  get baseSettings() {
    const customSettings = {
      create: this.createValue,
      createOnBlur: this.createOnBlurValue,
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue,
      delimiter: this.delimiterValue,
      allowEmptyOption: this.data.get('allowEmptyOption'),
      closeAfterSelect: this.data.get('closeAfterSelect'),
      onItemAdd: function(){
        // clear out the input field after selecting an item, and update the options
        this.setTextboxValue('')
        this.refreshOptions(false)
      },
      render: {
        item: this.renderItem.bind(this),
        option: this.renderOptionWithSubtext.bind(this),
      }
    }

    if (this.dropdownTarget.multiple) {
      Object.assign(customSettings, {
        maxItems: this.data.get('maxItems') ?? null, // allow max number of selected items, default is no max
        plugins: {
          'remove_button': {},
          'clear_button': {
            html: function (data) {
              return `<div class="${data.className} material-symbols-outlined" title="${data.title}">close</div>`;
            }
          }
        },
      })
    }

    return customSettings
  }

  renderOptionWithSubtext(data, escape) {
    if (this.multiLineDelimiterValue) {
      const [name, subtext] = data.name.split(this.multiLineDelimiterValue)
      return `
      <div className="option" role="option" id="${data.id}" data-value="${data.id}">
        <div>${escape(name)}</div>
        <div class="option-subtext">${escape(subtext)}</div>
      </div>`
    }

    return `
        <div className="option" role="option" id="${data.id}" data-value="${data.id}">
            ${escape(data.name)}
        </div>    
    `
  }

  renderItem(item, escape) {
    let originalName = item.name

    if (this.multiLineDelimiterValue) {
      // render only the first part of an item with subtext
      originalName = originalName.split(this.multiLineDelimiterValue)[0]
    }

    const escapedName = escape(originalName)
    return this.itemTemplate(item).replaceAll('$text$', escapedName)
  }

  itemTemplate(item) {
    if (this.hasItemTemplateValue) {
      return this.itemTemplateValue
    }
    return this.defaultItemTemplate(item)
  }

  defaultItemTemplate(item) {
    const value = item.$option?.value || item.name
    return `
      <div       
           data-value="${value}" 
           data-testid="item-${value}">
        ${name}
      </div>
    `
  }
}
