import DragDropFormController from '../javascript/controllers/drag_drop_form_controller'

export default class LineItemPriorityModalController extends DragDropFormController {
  connect() {
    super.connect()
    this.updateDisplayedPriorities()
    this.rowElements().forEach(element => element.classList.add('grab-hand'));
  }

  sortableConfig() {
    return {
      ...super.sortableConfig(),
      draggable: 'tbody',
      onEnd: this.onChangePriorities.bind(this)
    }
  }

  onChangePriorities() {
    this.enableSubmitButton()
    this.updateDisplayedPriorities()
  }

  enableSubmitButton() {
    document.querySelector('#change-priority-button').disabled = false
  }

  updateDisplayedPriorities() {
    const priorityCells = this.element.querySelectorAll('td.priority_number')
    priorityCells.forEach((tableCell, index) => {
      tableCell.innerText = index + 1
    })
  }

  rowElements() {
    return this.element.querySelectorAll('tr')
  }
}