import { Controller } from '@hotwired/stimulus'
import { useMapReduce } from '../mixins/use_map_reduce'

import {
  elementValue,
  setElementValue,
  isPresent
} from '../html_element_functions.js'

/**
 * Stimulus controller to provide a SINGLE signal indicating whether the fields in a fieldset are populated.
 * Two modes are supported: any or all.
 * ANY will conclude 'populated' if ANY of the fields in the fieldset are populated.
 * ALL will conclude 'populated' if ALL of the fields in the fieldset are populated.
 */
export default class PopulatedFieldsetController extends Controller {
  static targets = ['output', 'input']
  static values = {
    mode: {
      type: String,
      default: 'any', // any | all
    },
  }

  connect() {
    super.connect()
    this.detectInvalidSetup()
    useMapReduce(this, {
      input: () => this.inputTargets,
      map: elementValue,
      reduce: this.detectPopulatedFields,
      output: this.updateOutputElement
    })
  }

  detectInvalidSetup() {
    if (!this.hasOutputTarget) {
      throw new Error('Missing required configuration: output (target)')
    }

    if (!this.hasInputTarget) {
      throw new Error('Missing required configuration: input (target)')
    }

    if (this.modeValue.toLowerCase() !== 'any' && this.modeValue.toLowerCase() !== 'all') {
      throw new Error(`Invalid mode: ${this.modeValue}`)
    }
  }

  detectPopulatedFields(inputFieldValues) {
    if (this.modeValue.toLowerCase() === 'any') {
      return inputFieldValues.some(isPresent)
    } else if (this.modeValue.toLowerCase() === 'all') {
      return inputFieldValues.every(isPresent)
    }
  }

  updateOutputElement(fieldSetPopulated) {
    setElementValue(this.outputTarget, fieldSetPopulated ? 'fieldset is populated' : '')
  }
}