import { Controller } from '@hotwired/stimulus'

export default class PreventEnterController extends Controller {

  /**
   * Stimulus controller to prevent the enter button from interacting with the element the controller is assigned to.
   * Ideally used to prevent submission via the enter button on a form, by applying the controller to the form's submission element.
   */
  connect() {
    super.connect()
    this.stopSubmit()
  }

  stopSubmit() {
    this.element.addEventListener("keydown", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }
}