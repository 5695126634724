import { Controller } from '@hotwired/stimulus'
import { setElementValue } from '../html_element_functions'

export default class CounterController extends Controller {
  connect() {
    super.connect()
    this.counts = {}
  }

  setValue(counterName, counterValue) {
    if (this.counts[counterName] === undefined) {
      this.counts[counterName] = 0
    }
    this.counts[counterName] = counterValue
    setElementValue(this.element, this.sum())
  }

  sum() {
    return Object.values(this.counts).reduce((a, b) => a + b, 0)
  }
}