import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'
import { useEventListener } from 'stimulus-library'

export default class AutonumericController extends Controller {
  static get SHOW_AUTONUMERIC_WARNINGS() {
    // This is to silence a spurious warning when allowDecimalPadding is set to false.
    // Set to true to see configuration warnings in the console.
    return false
  }

  static values = {
    suffixText: String,
    decimalPlaces: String,
    currencySymbol: String,
    allowDecimalPadding: { type: Boolean, default: true },
    digitGroupSeparator: { type: String, default: ',' },
    emptyInputBehavior: { type: String, default: 'null' }
  }

  connect() {
    super.connect()
    this.detectInvalidSetup()
    this.element.autonumeric = new AutoNumeric(this.element, this.configOptions())
    this.disableAutocomplete()
    this.reformatAfterSubmit()
  }

  reformatAfterSubmit() {
    if (this.element.form) {
      //  https://github.com/autoNumeric/autoNumeric/issues/769
      useEventListener(this, this.element.form, 'turbo:submit-end', this.reformatValue)
    }
  }

  disableAutocomplete() {
    // Prevent autofill suggestions from being displayed on Autonumeric inputs
    // Issue detailed here: https://github.com/autoNumeric/autoNumeric/issues/536
    this.element.setAttribute('autocomplete', 'off')
  }

  detectInvalidSetup() {
    if (!this.hasDecimalPlacesValue) {
      throw new Error('Missing required configuration: decimalPlaces (value)')
    }
  }

  configOptions() {
    return {
      unformatOnSubmit: true,
      emptyInputBehavior: this.emptyInputBehaviorValue,
      modifyValueOnWheel: false,
      suffixText: this.suffixTextValue,
      decimalPlaces: this.decimalPlacesValue,
      currencySymbol: this.currencySymbolValue,
      currencySymbolPlacement: 'p',
      allowDecimalPadding: this.allowDecimalPaddingValue,
      digitGroupSeparator: this.digitGroupSeparatorValue,
      showWarnings: AutonumericController.SHOW_AUTONUMERIC_WARNINGS
    }
  }

  reformatValue() {
    this.element.autonumeric.reformat();
  }

  disconnect() {
    this.element.autonumeric.remove()
  }
}
