import Notification from 'stimulus-notification'

export default class extends Notification {

  static values = {
    ...Notification.values,
    autohide: Boolean
  }

  connect() {
    super.connect()
    this.alertElement().style.setProperty('--op-transition-alert', `${this.delayValue}ms`)
  }

  alertElement() {
    return this.element.querySelector('.alert')
  }

  show () {
    this.enter()

    if (this.autohideValue) {
      this.timeout = setTimeout(this.hide, this.delayValue)
    }
  }
}
