import { Controller } from '@hotwired/stimulus'
import { useResizeObserver } from "stimulus-library"

/**
 * Detects whether the element is truncated.
 * If so, adds the class 'truncated' to the element.
 * Monitors resize events of the parentElement to keep up to date.
 */
export default class DetectTruncationController extends Controller {
  connect() {
    super.connect()
    this.detect()
    useResizeObserver(this, this.element.parentElement, this.detect)
  }

  detect() {
    this.isTruncated() ?
      this.element.classList.add('truncated') :
      this.element.classList.remove('truncated')
  }

  isTruncated() {
    return this.horizontallyTruncated() || this.verticallyTruncated()
  }

  horizontallyTruncated() {
    return this.element.scrollWidth > this.element.clientWidth
  }

  verticallyTruncated() {
    return this.element.scrollHeight > this.element.clientHeight
  }
}