import { Controller } from '@hotwired/stimulus';
import { useCollectionEventListener } from 'stimulus-library'
import { elementValue } from '../../javascript/html_element_functions'

/**
 * Custom stimulus controller to refresh the contents of a dynamic form section based on source field change events.
 */
export default class DynamicFormSectionController extends Controller {

  static targets = ['action']

  static values = {
    sourceMatchers: Array
  }

  connect() {
    super.connect()
    this.monitorSourceFieldEvents()
  }

  monitorSourceFieldEvents() {
    useCollectionEventListener(this, this.sourceElements(), ['change'], this.refreshSectionContents.bind(this))
  }

  refreshSectionContents() {
    this.actionTarget.search = this.sourceFieldValues()
    this.actionTarget.click()
  }

  sourceElements() {
    if (!this._sourceElements) {
      this._sourceElements = this.sourceMatchersValue.flatMap(matcher => Array.from(document.querySelectorAll(matcher)))
    }
    return this._sourceElements
  }

  sourceFieldValues() {
    const enabledFields = this.sourceElements().filter(e => !e.disabled)
    return this.encodeSourceFieldValues(enabledFields)
  }

  encodeSourceFieldValues(fields) {
    return fields.reduce((params, element) => {
      const name = element.name
      const value = elementValue(element)
      params.append(name, value)
      return params
    }, new URLSearchParams())
  }
}