import { Controller } from '@hotwired/stimulus'
import { useCollectionEventListener } from 'stimulus-library'
import {isBlank} from "../../../../../core/core_ui/app/javascript/html_element_functions";

export default class DirtyFilterFormController extends Controller {
  static values = {
    enabled: Boolean
  }

  connect() {
    super.connect()
    useCollectionEventListener(this, this.formElements, 'change', this.setFormDataDirty)
  }

  setFormDataDirty(event) {
    if(!this.enabledValue || this.filterIsInactive(event.target)) {
      return
    }

    this.element.setAttribute('data-dirty', true)
  }

  filterIsInactive(element){
    if(element.name.includes('[exclude]')){
      return this.filterValueIsBlank(element, 'exclude')
    }
    if(element.name.includes('[operator]')){
      return this.filterValueIsBlank(element, 'operator')
    }

    return false
  }

  filterValueIsBlank(checkboxElement, fieldName){
    const filterName = checkboxElement.name.split(`[${fieldName}]`)[0]
    const filterValueInput = this.getFilterValueInput(filterName)
    return isBlank(filterValueInput.value);
  }

  getFilterValueInput(filterName){
    const filterInputs = this.formElements.filter(e => e.name.includes(filterName) && e.name.includes('[value]'));
    return filterInputs[0]
  }

  get formElements(){
    return Array.from(this.element.elements)
  }

  enable(){
    this.enabledValue = true
    this.clearFormDirtyState()
  }

  clearFormDirtyState(){
    this.element.removeAttribute('data-dirty')
  }
}
