import { Controller } from '@hotwired/stimulus';

/**
 * Stimulus controller to accompany the LiveInputField. This is responsible for
 * monitor keyDown events, to interpret an ESC keypress as a cancellation of the edit
 * (and restore the value to initial value)
 */
export default class LiveInputFieldController extends Controller {
  static targets = ['input']
  static values = {
    initial: String
  }

  cancelEdit() {
    this.inputTarget.value = this.initialValue
    this.inputTarget.blur()
  }

  handleKeydown(event) {
    if (event.keyCode === 27) {
      this.cancelEdit()
    } else if (event.keyCode === 13) {
      this.preventDuplicateSubmit(event)
      this.inputTarget.blur();
    }
  }

  preventDuplicateSubmit(event) {
    event.stopPropagation()
    event.preventDefault()
  }
}