import { Controller } from '@hotwired/stimulus';
import { computePosition, flip, shift, limitShift, offset, arrow, autoUpdate } from '@floating-ui/dom'
import { useEventListener } from 'stimulus-library'

export default class TooltipController extends Controller {
  static targets = ['tooltip', 'element', 'arrow'];
  static values = {
    placement: {type: String, default: "top"},
    offset: {type: Number, default: 8},
  }

  connect() {
    super.connect()
    this.detectInvalidSetup()
    this.fixupDOM()
    this.monitorMouseEvents()
    this.keepTooltipPositioned()
  }

  keepTooltipPositioned() {
    autoUpdate(this.elementTarget, this.tooltipTarget, this.positionTooltip.bind(this))
  }

  monitorMouseEvents() {
    useEventListener(this, this.elementTarget, 'mouseenter', this.show)
    useEventListener(this, this.elementTarget, 'mouseleave', this.hide)
  }

  fixupDOM() {
    this.ensureElementHasClass(this.element, 'tooltip-controller')
    this.ensureElementHasClass(this.tooltipTarget, 'tooltip')
    this.tooltipTarget.setAttribute('role', 'tooltip')
    if (!this.hasArrowTarget) {
      this.createDefaultArrowTarget()
    }
  }

  detectInvalidSetup() {
    if (!this.hasElementTarget) {
      throw new Error('Missing required configuration: element (target)')
    }

    if (!this.hasTooltipTarget) {
      throw new Error('Missing required configuration: tooltip (target)')
    }

    // if blank value is supplied in DOM, default value will not be used
    if (this.placementValue === '') {
      throw new Error('Missing required configuration: placement (value)')
    }

    // if blank value is supplied in DOM, default value will not be used
    if (this.offsetValue === '') {
      throw new Error('Missing required configuration: offset (value)')
    }
  }

  createDefaultArrowTarget() {
    const arrowTarget = document.createElement('span')
    arrowTarget.classList.add('tooltip-arrow')
    arrowTarget.setAttribute(`data-${this.identifier}-target`, 'arrow')
    arrowTarget.setAttribute('data-popper-arrow', '')
    this.tooltipTarget.appendChild(arrowTarget)
  }

  ensureElementHasClass(element, className) {
    if (!element.classList.contains(className)) {
      element.classList.add(className);
    }
  }

  async positionTooltip() {
    const {x, y, middlewareData, placement} = await computePosition(this.elementTarget, this.tooltipTarget, this.tooltipOptions())
    Object.assign(this.tooltipTarget.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
    this.positionArrow(middlewareData.arrow, placement)
  }

  positionArrow(arrowData, tooltipPlacement) {
    if (!arrowData) {
      return
    }

    const {x, y} = arrowData;
    const arrowPlacement = this.arrowPlacement(tooltipPlacement)
    Object.assign(this.arrowTarget.style, {
      left: x != null ? `${x + (this.arrowTarget.offsetWidth / 3)}px` : '',
      top: y != null ? `${y - (this.arrowTarget.offsetWidth / 3)}px` : '',
      [arrowPlacement]: `${-this.arrowTarget.offsetWidth / 2}px`
    })
  }

  arrowPlacement(tooltipPlacementExpression) {
    const tooltipPlacement = tooltipPlacementExpression.split('-')[0]
    return {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right'
    }[tooltipPlacement]
  }

  tooltipOptions() {
    return {
      placement: this.placementValue,
      strategy: "fixed",
      middleware: [
        flip(),
        shift({
          limiter: limitShift()
        }),
        arrow({element: this.arrowTarget}),
        offset({mainAxis: this.offsetValue}),
      ]
    }
  }

  show(_event) {
    this.tooltipTarget.setAttribute("data-show", "");
  }

  hide(_event) {
    this.tooltipTarget.removeAttribute("data-show");
  }
}
