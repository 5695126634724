import { Controller } from '@hotwired/stimulus';
import { Turbo } from "@hotwired/turbo-rails"

export default class SidebarController extends Controller {
  toggle() {
    this.element.classList.toggle('sidebar--drawer')
    this.element.classList.toggle('sidebar--rail')
    Turbo.cache.clear()
  }

}