import { Controller } from '@hotwired/stimulus';
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';

Sortable.mount(new AutoScroll());

/**
 * Stimulus controller to provide drag-and-drop capability on a form (ie, no live update).
 */
export default class DragDropFormController extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, this.sortableConfig())
  }

  sortableConfig() {
    return {
      ghostClass: 'ghost-row',
      scroll: true,
      forceAutoScrollFallback: true,
      scrollSensitivity: 100,
    }
  }
}
