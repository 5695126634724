import { Controller as StimulusController } from '@hotwired/stimulus'

/**
 * Stimulus controller for the turbo drawer component. This must be attached to the sl-drawer shoelace component.
 *
 * 1) Transfer additionalClasses from the headerTarget to the element
 * 2)
 */
export default class TurboDrawerController extends StimulusController {
  static targets = ['header', 'contents']
  static values = {
    shouldOpen: Boolean
  }

  connect() {
    super.connect()
    this.loaded()
  }

  loaded() {
    if (!this.element.open && this.shouldOpenValue === true) {
      /**
       * In order to get opening animations, invoke the open method later in the JS event cycle
       */
      setTimeout(() => { this.open() }, 1)
    }

    /**
     * Transfer the additionalClasses from the headerTarget to the element.
     */
    if (this.hasHeaderTarget && this.headerTarget.dataset.additionalClasses !== '') {
      this.element.classList.add(this.headerTarget.dataset.additionalClasses)
    }

    this.requireExplicitClose()
  }

  open() {
    this.element.show()
  }

  close() {
    this.element.hide()
  }

  onHide() {
    /**
     * Remove the additionalClasses from the drawer when it is closed.
     */
    setTimeout(() => {
      if (this.hasHeaderTarget && this.headerTarget.dataset.additionalClasses !== '') {
        this.element.classList.remove(this.headerTarget.dataset.additionalClasses)
      }

      /**
       * Discard the drawer contents when closing the drawer.
       */
      this.element.parentNode.replaceChildren()
    }, 100) // Ensure the modal has finished its hide animation before clearing the contents out of it.
  }

  requireExplicitClose(){
    /**
     * Prevent the drawer from being closed by the user clicking outside of it, or by pressing ESC.
     */
    this.element.addEventListener('sl-request-close', (event) => {
      if (event.detail.source === 'overlay' || event.detail.source === 'keyboard') {
        event.preventDefault()
      }
    })
  }

}
