import { Controller } from '@hotwired/stimulus'
import { useMapReduce } from '../mixins/use_map_reduce'

import {
  elementValue,
  setElementValue,
  isPresent
} from '../html_element_functions.js'

/**
 * This stimulus controller counts the number of form fields that are populated.
 * Input target: The form fields to monitor.
 * Output target: The element to update with the count of populated fields.
 * Counter outlet: The counter controller that sums the populated fields within each section. (optional)
 * If the counter outlet is used, the data-counter-category attribute must be set on this element
 */
export default class PopulatedFieldsController extends Controller {
  static targets = ['output', 'input']
  static outlets = ['counter']

  connect() {
    super.connect()
    this.detectInvalidSetup()
    this._collectionPipeline = useMapReduce(this, {
      input: () => this.inputTargets,
      name: 'populatedFields',
      map: elementValue,
      reduce: fieldValues => fieldValues.filter(isPresent).length,
      output: this.updateOutputElements
    })
    this.updateCollection = this._collectionPipeline.updateCollection
  }

  inputTargetConnected(_element) {
    if (this.updateCollection) {
      this.updateCollection()
    }
  }

  inputTargetDisconnected(_element) {
    this.updateCollection()
  }

  detectInvalidSetup() {
    if (!this.hasOutputTarget) {
      throw new Error('Missing required configuration: output (target)')
    }

    if (!this.hasInputTarget) {
      throw new Error('Missing required configuration: input (target)')
    }

    if (this.hasCounterOutlet && !this.data.has('counterCategory')) {
      throw new Error('Missing required configuration: data-counter-category (attribute)')
    }
  }

  updateOutputElements(populatedFieldCount) {
    setElementValue(this.outputTarget, populatedFieldCount)
    if (this.hasCounterOutlet) {
      this.counterOutlet.setValue(this.data.get('counterCategory'), populatedFieldCount)
    }
  }
}