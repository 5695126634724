/**
 * This mixin provides a way to monitor changes to a collection of elements, analyze the changes via a map/reduce
 * pipeline, and output the result to a target element.
 */
class ElementMapReducePipeline {
  #name
  #inputFxn
  #mapFxn
  #reduceFxn
  #outputFxn
  #events
  #teardownCallbacks

  /**
   * Initialize a new element collection pipeline.
   * @param {String} name - A name for the pipeline, for debugging purposes.
   * @param {Function} input - A function returning a set of HTMLElements to monitor.
   * @param {Function} map - A function that takes an array of HTMLElements, and returns an array of values to reduce.
   * @param {Function} reduce - A function that take an array of values, and returns a single value.
   * @param {Function} output - A function that takes the reduced value and affects the DOM based on the value.
   * @param [Array] events - An array of event types, as strings, to observe */
  constructor({ name = null, input, map, reduce, output, events = ['input', 'change']}) {
    this.#name = name
    this.#inputFxn = input
    this.#mapFxn = map
    this.#reduceFxn = reduce
    this.#outputFxn = output
    this.#events = events
    this.execute = this.execute.bind(this)
    this.startObserving = this.startObserving.bind(this)
    this.stopObserving = this.stopObserving.bind(this)
    this.updateCollection = this.updateCollection.bind(this)
    this.execute()
    this.startObserving()
    // console.log('initialized pipeline', this.#name)
  }

  execute() {
    const mappedValues = this.#elementCollection.map(this.#mapFxn)
    const result = this.#reduceFxn(mappedValues)
    this.#outputFxn(result)
    // console.log('executed pipeline', this.#name, result, mappedValues)
  }

  updateCollection() {
    this.stopObserving()
    this.execute()
    this.startObserving()
    // console.log(`updated pipeline ${this.#name}, now monitoring ${this.#elementCollection.length} elements`)
  }

  startObserving() {
    this.#teardownCallbacks = []
    for (const elements of this.#elementCollection) {
      for (const event of this.#events) {
        elements.addEventListener(event, this.execute)
        this.#teardownCallbacks.push(() => {
          elements.removeEventListener(event, this.execute)
        })
      }
    }
  }

  stopObserving() {
    for (const teardown of this.#teardownCallbacks) {
      teardown()
    }
  }


  get #elementCollection() {
    return Array.from(this.#inputFxn())
  }
}

/**
 * This function is a factory for creating a map/reduce pipeline for a stimulus controller.
 * @param {Controller} controller - A stimulus controller into which to mix the element collection behavior.
 * @param {String} name - A name for the pipeline, for debugging purposes.
 * @param {Function} input - A function returning a set of HTMLElements to monitor.
 * @param [Array] events - An array of event types, as strings, to observe.
 * @param {Function} map - A function that takes an array of HTMLElements, and returns an array of values to reduce.
 * @param {Function} reduce - A function that take an array of values, and returns a single value.
 * @param {Function} output - A function that takes the reduced value and affects the DOM based on the value.
 * @returns {Function} updateCollection
 */
export function useMapReduce(controller, { name = null, input, events, map, reduce, output }) {
  const pipeline = new ElementMapReducePipeline({
    name: name,
    input: input.bind(controller),
    events: events,
    map: map.bind(controller),
    reduce: reduce.bind(controller),
    output: output.bind(controller)
  })

  // keep a copy of the current disconnect() function of the controller
  // to support composing several behaviors
  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect() {
      pipeline.stopObserving()
      controllerDisconnect()
    }
  })

  return pipeline
}