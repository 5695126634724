import { Controller } from '@hotwired/stimulus';

export default class LiveStringInputFieldController extends Controller {
  static targets = ['display', 'form', 'input']
  static values = {
    initial: String,
    firstTimeEdit: Boolean
  }

  connect() {
    super.connect()
    if (this.inEditMode()) {
      this.inputTarget.focus()
      if (this.firstTimeEditValue) {
        this.inputTarget.select()
      }
    }
  }
  
  edit() {
    this.displayTarget.hidden = true
    this.formTarget.hidden = false
    this.positionCursorAtEndOfInput()
  }

  positionCursorAtEndOfInput() {
    // move window focus to the input
    this.inputTarget.focus()
    // position cursor at end of input
    const initialPosition = this.initialValue.length
    this.inputTarget.setSelectionRange(initialPosition, initialPosition);
    // possibly scroll input so that end of text is in view
    this.inputTarget.scrollLeft = this.inputTarget.scrollWidth;
  }

  show() {
    this.displayTarget.hidden = false
    this.formTarget.hidden = true
  }

  cancelEdit() {
    this.show()
  }

  inEditMode() {
    return this.displayTarget.hidden
  }

  handleBlur(_event) {
    if (this.inEditMode() && this.inputTarget.value === this.initialValue) {
      this.cancelEdit()
    }
  }

}